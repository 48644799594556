// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#ScrollTop {
  position: fixed;
  background-color: black;
  z-index: 9;
  opacity: 0.5;
  padding: 7px;
  bottom: 15px;
  right: 15px;
  border-radius: 5px;
  cursor: pointer;
}
#ScrollTop :hover {
  opacity: 0.8;
}

.Activate {
  display: block;
}

.Disable {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/sass/ScroollTop.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,uBAAA;EACA,UAAA;EACA,YAAA;EACA,YAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,eAAA;AACF;AAAE;EACE,YAAA;AAEJ;;AACA;EACE,cAAA;AAEF;;AAAA;EACE,aAAA;AAGF","sourcesContent":["#ScrollTop {\n  position: fixed;\n  background-color: black;\n  z-index: 9;\n  opacity: 0.5;\n  padding: 7px;\n  bottom: 15px;\n  right: 15px;\n  border-radius: 5px;\n  cursor: pointer;\n  :hover {\n    opacity: 0.8;\n  }\n}\n.Activate {\n  display: block;\n}\n.Disable {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
