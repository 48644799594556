// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*Components Photo, SlideShow*/
/*Components Photo, SlideShow*/`, "",{"version":3,"sources":["webpack://./src/sass/_variables.scss"],"names":[],"mappings":"AA+EA,8BAAA;AAAA,8BAAA","sourcesContent":["// $h1size: calc(1em + 1.4vw);\n$h1size: 40px;\n$h1res4: calc(1em + 1vw); // 390px\n$h1res5: calc(1em + 0.8vw); // 320px\n\n$h2size: calc(1em + 0.8vw);\n$h2res4: calc(1em + 2vw); // 390px\n\n$h3size: 18px;\n$h3res4: calc(1em + 1vw);\n// $h3size: calc(1em + 1.1vw);\n\n// $p1size: calc(1em + 0.3vw);\n$p1size: 16px;\n$p2size: calc(0.8em + 0.1vw); // 390px\n\n// 1280 px et plus\n// 1024 px\n// 768 px\n// 480 px\n// 320 px\n\n$p3size: calc(0.6em + 0.1vw);\n\n$title: \"Roboto\", sans-serif;\n$text: \"Poppins\", sans-serif;\n\n$header-back-color: #a7d8d1;\n$footer-back-color: #1d5c7d;\n\n$colorDark1: #0d3155;\n$colorDark2: #335578;\n$colorDark3: #155e75;\n$colorDark4: #1d5c7d;\n$colorDark5: #060724;\n\n$blue: #8290fe;\n$blue1: #0c71c3;\n$blue2: #3366ff;\n$blue3: #cbe9ff;\n$blue4: #2e51a6;\n\n$title-color: #0d3155;\n\n$text-color: #335578;\n\n$border-color: rgb(229, 231, 235);\n\n$button-back: #0d3155;\n$hover-button-back: #13aff0;\n$button-color: #ffff;\n\n$size-titleH2: 30px;\n$size-titleH1: 38px;\n$size-text: 25px;\n\n$weight-title: 800;\n$weight-text: 700;\n\n$otionSelect: linear-gradient(\n    0deg,\n    rgba(255, 255, 255, 0.6),\n    rgba(255, 255, 255, 0.6)\n  ),\n  #1b5a7d;\n\n//Solution\n$text-back: #155e75;\n$shadow: 0px 4px 4px rgba(79, 70, 229, 0.2);\n$family: Verdana, Geneva, Tahoma, sans-serif;\n$familyGlobal: \"Segoe UI\", Tahoma, Geneva, Verdana, sans-serif;\n$number: 55px;\n//Margin\n$Margin: 25px;\n$MR: 88px;\n$ModalTop: 100%;\n//Menu hamburger\n$widthMenu: 170px;\n$red: #da1e28;\n/*Components Photo, SlideShow*/\n$VideoPhotoSlide-width: 100%;\n$VideoPhotoSlide-height: 600px;\n$VideoPhotoMarginBottom: 40px;\n\n//DocTechnic\n$DTheightBlocks: 46vh;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
